

ul.nav {
    list-style: none;
    padding: 0;

    li {
        margin-bottom: var(--margin-s);
        font-size: var(--font-l);

        .icon-text {
            color: var(--c-gray-2);
            transition: color 200ms ease;
        }

        a {
            display: block;
            height: auto;
            width: 100%;

            &.active {
               font-weight: bold;

                .icon-text {
                    color: var(--c-gray-1);
                }


            }
        }

        a:hover {
            text-decoration: none;

            .icon-text {
                color: var(--c-gray-1);

            }
        }
    }

    li.nav-header {
        font-size: var(--font-xl);
      //  margin-top: var(--margin-xl);
        color: var(--c-lead-2);


        &:first-of-type {
            margin-top: 0;
        }
    }

    li.nav-divider {
        height: 0;
        margin: var(--margin-xl) 0;
        width: 100%;
        flex: 100%;
        border-bottom: 1px solid var(--c-gray-4);
    }
}
